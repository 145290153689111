/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { VideoStories } from '@/store/videoStories/types';

const namespace: string = 'videoStories';

export default class VideoStoriesVideoViewingPage extends Vue {
	@State('videoStories') videoStories: VideoStories | undefined;
	@Getter('getChosenVideo', {namespace}) getChosenVideo: any;
	@Getter('getChosenModels', {namespace}) getChosenModels: any;
	@Getter('getVideoList', {namespace}) getVideoList: any;
	@Getter('getMyCount', {namespace}) getMyCount: any;
	@Mutation('setChosenVideo', {namespace}) setChosenVideo: any;
	@Action('payVideo', {namespace}) payVideo: any;

	gotoModelChoice() {
		this.$router.push({ name: 'video-stories-model-choice' }).then(r => r);
	}
	gotoVideoChoice() {
		this.$router.push({ name: 'video-stories-video-choice' }).then(r => r);
	}
	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			// this.storeModelViewingPeep({
			// 	peep: true
			// });
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'min':
			// this.storeModelViewingPerMinOrAll({
			// 	perMinutes: true
			// })
			break;
		case 'allShow':
			// this.storeModelViewingPerMinOrAll({
			// 	allShow: true
			// })
			break;
		default:
			break;
		}
	}
	selectedVideo(video: Object) {
		this.setChosenVideo(video);
		this.$router.push({ name: 'video-stories-video-chosen' }).then(r => r);
	}
}
